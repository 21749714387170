<template>
  <div class="intro-wrap">
    <div class="row sms-user-intro">
      <div class="intro-wrap-tit">
        <img src="/image/logo_white.png" alt="로고" style="width: 70%">
      </div>
      <div class="intro-wrap-btn">
        <img src="/image/kakao.png" alt="카카오톡">
      </div>
      <button class="btn-l color-white">월세 결제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentPayIntro",

  mounted() {
    let url = window.location.href
    let token = url.split('token=')[1]

    setTimeout(() => {
      this.$router.push({ name: 'RentPayInfo', params: { token: token }})
    }, 1000)
  }
}
</script>

<style scoped>

</style>