import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import SMSUserIntro from "@/components/smsSentPayment/SMSUserIntro";
import SMSUserPayInfo from "@/components/smsSentPayment/SMSUserPayInfo";
import SMSUserPayCard from "@/components/smsSentPayment/SMSUserPayCard";
import SMSUserPaySuccess from "@/components/smsSentPayment/SMSUserPaySuccess";
import SMSUserPayFail from "@/components/smsSentPayment/SMSUserPayFail";
import SMSPayError from "@/components/smsSentPayment/SMSPayError";
import RentPayIntro from "@/components/rentPayment/RentPayIntro";
import RentPayInfo from "@/components/rentPayment/RentPayInfo";
import RentPayInfoCard from "@/components/rentPayment/RentPayInfoCard";
import RentPaySuccess from "@/components/rentPayment/RentPaySuccess";
import RentPayFail from "@/components/rentPayment/RentPayFail";
import RentPayError from "@/components/rentPayment/RentPayError";

export default new Router({
    routes: [
        // 카톡결제
        {
            path: '/payment/sms',
            name: 'SMSUserIntro',
            component: SMSUserIntro,
        },
        {
            path: '/payment/sms/info',
            name: 'SMSUserPayInfo',
            component: SMSUserPayInfo,
        },
        {
            path: '/payment/sms/card',
            name: 'SMSUserPayCard',
            component: SMSUserPayCard,
        },
        {
            path: '/payment/sms/success',
            name: 'SMSUserPaySuccess',
            component: SMSUserPaySuccess,
        },
        {
            path: '/payment/sms/fail',
            name: 'SMSUserPayFail',
            component: SMSUserPayFail,
        },
        {
            path: '/payment/sms/error',
            name: 'SMSPayError',
            component: SMSPayError,
        },

        // 월세결제
        {
            path: '/monthly/sms',
            name: 'RentPayIntro',
            component: RentPayIntro,
        },
        {
            path: '/monthly/sms/info',
            name: 'RentPayInfo',
            component: RentPayInfo,
        },
        {
            path: '/monthly/sms/card',
            name: 'RentPayInfoCard',
            component: RentPayInfoCard,
        },
        {
            path: '/monthly/sms/success',
            name: 'RentPaySuccess',
            component: RentPaySuccess,
        },
        {
            path: '/monthly/sms/fail',
            name: 'RentPayFail',
            component: RentPayFail,
        },
        {
            path: '/monthly/sms/error',
            name: 'RentPayError',
            component: RentPayError,
        },

    ]
})
