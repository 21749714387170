<template>
  <div class="confirm-wrap" v-if="show" @click.self="close()">
    <div class="confirm-layer intro-Permission">
      <div class="confirm-layer-box">
<!--        <img src="/image/logo_vertical.svg" alt="로고">-->
        <h2>앱 접근 권한 안내</h2>
        <p>결제 플랫폼, PAYSTAR 이용을 위해<br>
          다음 권한의 허용이 필요합니다.</p>
      </div>
      <div class="confirm-layer-box">
        <ul>
          <li>
            <span><b>전화</b>(필수)</span>
            <em class="color-darkgray">휴대폰번호와 사용자 동일여부 확인</em>
          </li>
          <li>
            <span><b>카메라</b>(필수)</span>
            <em class="color-darkgray">결제시 QR촬영 기능 제공</em>
          </li>
        </ul>
      </div>
      <button class="btn btn-m btn-pink" @click="close()">
        확인
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionRequest",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    close() {
      this.show = false
    },
  }
}
</script>

<style scoped>

</style>