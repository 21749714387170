<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list" v-show="installmentList ? installmentList.length > 0 : false" style="height: 60%;">
      <h5 class="pt-0">할부</h5>
      <ul style="overflow: auto; height: calc(100% - 60px)">
        <li v-for="(installment, idx) in installmentList" :key="idx">
          <a @click="selectInstallment(installment)">
            <span>{{ installment.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SMSPaySelect",

  props: ['installmentList'],
  methods: {
    selectInstallment(installment) {
      this.$emit('selectedInstallment', installment)
    },
  },
}
</script>

<style scoped>

</style>
