<template>
  <div class="input-close h-100 border-zero">
    <input :type="type"
           :placeholder="placeholder"
           v-model.trim="inputValue"
           @focusin="isFocus = true"
           @focusout="isFocus = false"
           :disabled="disable"
           :class="{'font-28' : isBig, 't-c-15px' : isCenter, 'border-pink': isFocus }"
           class="h-100"
           :inputmode="inputMode"
           :maxlength="maxLength > 0 ? maxLength : ''"
    />
    <button v-show="inputValue !== '' && !disable" type="button" class="close-circle" @click="deleteAll()"></button>
  </div>
</template>

<script>
export default {
  name: "RPInputBorder",
  props: {
    model: {
      required: true
    },
    placeholder: {
      required: false
    },
    type: {
      required: true
    },
    disable: {
      required: false
    },
    isBig: {
      required: false,
      type: Boolean,
      default: false,
    },
    isCenter: {
      required: false,
      type: Boolean,
      default: false,
    },
    inputMode: {
      required: false,
      type: String,
      default: '',
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      inputValue: '',
      isFocus: false,
    }
  },

  created() {
    this.inputValue = this.model
  },

  methods: {
    deleteAll() {
      this.inputValue = ''
      this.$emit('update', '')
    },
  },

  watch: {
    inputValue() {
      this.$emit('update', this.inputValue)
    },

    model() {
      this.inputValue = this.model
    }
  }
}
</script>

<style scoped>
.t-c-15px {
  text-align: center;
  padding-right: 15px !important;
}
</style>
