<template>
  <div class="confirm-wrap" v-if="show" @click.self="close()">
    <div class="confirm-layer">
      <div class="character mb-20" v-show="showImage">
        <img src="/image/character_cheer_half.svg" alt="캐릭터">
      </div>
      <div class="confirm-layer-box mb-20" :class="{'mt-30' : !showImage}">
        <h3 v-if="title !== ''">{{ title }}</h3>
        <p :class="{ 'pt-15': title !== '' }" v-html="msg"></p>
      </div>
      <button class="btn btn-m btn-dgray w-50per round-lb" @click="no()">{{ textNo }}</button>
      <button class="btn btn-m btn-pink w-50per round-rb" @click="ok()">{{ textOk }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      show: false,
      title: '',
      msg: '',
      callback: null,
      cancel: null,
      textOk: '',
      textNo: '',
      showImage: false,
    }
  },
  methods: {
    showDialog(title, msg, textOk, textNo, callback, cancel, showImage = false) {
      this.title = title
      this.msg = msg
      this.textOk = textOk
      this.textNo = textNo
      this.callback = callback
      this.cancel = cancel
      this.showImage = showImage
      this.show = true
    },
    close() {
      this.show = false
    },
    ok() {
      this.close()
      this.callback()
    },
    no() {
      this.close()
      this.cancel()
    }
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 100;
  background: #fff;
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 50%;
}
.overlay_bg {
  z-index: 100;
}
</style>
