<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.push({ name: 'RentPayIntro' })"><i class="icon-back"></i></button>
      <p>월세 결제 정보</p>
    </header>
    <div class="content-wrap" style="height: auto">
      <div class="main-wrap" style="padding-bottom: 14px">
        <div class="charge-form pt-0">
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">월세</p>
          <p class="fz-16 color-gray lh-36">결제자<span class="item-name">{{ info.userHp | makePhoneNumber }}</span></p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">결제정보</p>
          <p class="fz-16 color-gray lh-36">납입금액<span class="item-name">{{ info.amount | numberWithCommas }}원</span></p>
          <p class="fz-16 color-gray lh-36">수수료({{ info.feeRate }}%)<span class="item-name">{{ info.feeAmount | makeComma }}원</span></p>
          <p class="fz-16 color-gray lh-36">총 결제금액<span class="item-name">{{ info.totalAmount | makeComma }}원</span></p>
        </div>
      </div>
      <div class="bar-10"></div>
      <button class="align-bottom btn btn-m btn-pink" @click="$router.push({ name: 'RentPayInfoCard', params: { amount: info.amount, token: token } })">다음</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentPayInfo",

  data() {
    return {
      token: this.$route.params.token,
      info: {
        userHp: '',
        amount: 0,
        feeRate: 0,
        feeAmount: 0,
        totalAmount: 0,
      }
    }
  },
  created() {
    this.$get(this.$DOMAIN_ADMIN, this.$PAYMENT_SMS_RENT + '?token=' + this.token, 'get_payment_rent_sms', (result) => {
      this.info = result.data
    }, (e) => {
      let message = ''
      if (e.data && e.data.message) {
        message = e.data.message
      }
      this.$router.push({ name: 'RentPayError', params:{message:message} })
    })
  }
}
</script>

<style scoped>

</style>
